import { Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { Widget } from './base_widget';
import { AppState } from '../../reducers';
import { Dispatch } from '@reduxjs/toolkit';
import { Strings } from '../../assets/common/strings';
import { themePalette } from '../../utilities/branding';
import { useNavigateTo } from '../../utilities/hooks';
import { navRoutes } from '../nav/Routes';
import LeadConfigSvg from '../svgs/icons/lead_config';
import { LeadOrderStatusTile } from '../leadOrderStatus/lead_order_status_tile';
import { TransferStatus } from '../../reducers/LeadReducer';
import {
	GetLeadConnectStatus,
	ToggleLeadConnectLeads,
} from '../../actions/lead_actions';
import { jwt_auth } from '../../utilities/auth';
import { P } from '../../utilities/auth/permissions';

interface StateProps {
	isReceiveLiveTransferLeads: TransferStatus;
	isReceiveLeadConnectLeads: TransferStatus;
	isLoading: boolean;
	agentCode: string;
	allowManageLeadOrders: boolean;
}

interface DispatchProps {
	getLeadConnectStatus: (agentCode: string) => void;
	toggleLeadConnectLeads: (
		type: string,
		isReceiving: TransferStatus,
		agentCode: string
	) => void;
}

type Props = StateProps & DispatchProps;

const LosWidget: React.FC<Props> = (props) => {

	const {
		agentCode, isLoading, getLeadConnectStatus, toggleLeadConnectLeads,
		isReceiveLeadConnectLeads, isReceiveLiveTransferLeads, allowManageLeadOrders
	} = props;

	useEffect(() => {
		if (agentCode) {
			getLeadConnectStatus(agentCode);
		}
	}, [agentCode]);

	const navToLeads = useNavigateTo(navRoutes.leadCircle.path);

	const renderWidget = () => {
		return <LeadOrderStatusTile
			isReceivingLeadConnectLeads={isReceiveLeadConnectLeads}
			isReceivingLiveTransferLeads={isReceiveLiveTransferLeads}
			toggleLeadConnectLeads={(isReceiving: TransferStatus) =>
				toggleLeadConnectLeads('Global', isReceiving, agentCode)
			}
			toggleLiveTransferLeads={(isReceiving: TransferStatus) =>
				toggleLeadConnectLeads('LiveTransfer', isReceiving, agentCode)
			}
			canUpdate={jwt_auth.hasPermission(P.LeadConnectUpdate) && !isLoading} />;
	};

	return (
		<Widget
			title={Strings.WidgetTitles.Leads}
			avatar={<LeadConfigSvg width={30} height={30} fill={themePalette.menuSvgLeads} />}
			actionButtons={[<Button onClick={navToLeads}>View All</Button>]}>
			{renderWidget()}
		</Widget>
	);
};

const mapStateToProps = (state: AppState): StateProps => ({
	agentCode: state.agent.agentCode,
	isReceiveLiveTransferLeads: state.lead.isReceivingLiveTransferLeads,
	isReceiveLeadConnectLeads: state.lead.isReceivingLeadConnectLeads,
	isLoading: state.lead.isLoading,
	allowManageLeadOrders: state.agent.agency !== 'FirstFamily',
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	getLeadConnectStatus: (agentCode: string) =>
		dispatch(GetLeadConnectStatus({ agentId: agentCode })),
	toggleLeadConnectLeads: (type: string, isReceiving: TransferStatus, agentCode: string) =>
		dispatch(
			ToggleLeadConnectLeads({
				agentId: agentCode,
				type: type,
				status: isReceiving,
			})
		)
});

export const LosWidgetContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
	true
)(LosWidget);